import React from 'react'
import SpotifyIcon from 'react-icons/lib/fa/spotify'
import FacebookIcon from 'react-icons/lib/fa/facebook'
import InstagramIcon from 'react-icons/lib/fa/instagram'
import SubscribeButton from '../components/SubscribeButton'

const IconButton = ({ href, children }) => {
	return (
		<a href={href} className="footer-link">
			{children}
		</a>
	)
}

const Footer = () => {
	const urls = [
		'https://www.facebook.com/TheHumanNature/',
		'https://www.instagram.com/thehumannatureofficial/'
	]
	return (
		<footer>
			<div className="container">
				<IconButton href="https://www.facebook.com/TheHumanNature/">
					<FacebookIcon />
				</IconButton>
				<IconButton href="https://www.instagram.com/thehumannatureofficial/">
					<InstagramIcon />
				</IconButton>
				<IconButton href="https://open.spotify.com/artist/00m9Z2Oqpwc6dgneEsKklB">
					<SpotifyIcon />
				</IconButton>
				<SubscribeButton />
			</div>
		</footer>
	)
}

export default Footer
