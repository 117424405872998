import React, { Component } from 'react'
import PropTypes from 'prop-types'

class FullscreenImage extends Component {
	constructor(props) {
		super(props)
		this.imgRef = null
	}

	componentDidMount() {
		if (this.imgRef.complete) {
			this.props.loaded()
		}
	}

	render() {
		const { image, loaded } = this.props
		return (
			<div className="fullscreen-image">
				<img ref={r => (this.imgRef = r)} src={image} onLoad={loaded} />
			</div>
		)
	}
}

FullscreenImage.propTypes = {
	image: PropTypes.string.isRequired,
	loaded: PropTypes.func
}

FullscreenImage.defaultProps = {
	loaded: () => {}
}

export default FullscreenImage
