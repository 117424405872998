import React from 'react'
import { Link } from 'gatsby'

const Header = () => {
  const MenuItem = ({ to, name }) => (
    <li className="pure-menu-item">
      <Link to={to} className="pure-menu-link" activeClassName="active" >
        {name}
      </Link>
    </li>
  )

  return (
    <header className="home-menu pure-menu pure-menu-horizontal pure-menu-fixed">
      <div className="container">
        <Link to="/" className="pure-menu-heading" >
          # The Human Nature
        </Link>

        <ul className="pure-menu-list">
          <MenuItem to="/" name="HOME" />
          <MenuItem to="/tour" name="TOUR" />
          <MenuItem to="/video" name="VIDEO" />
          <MenuItem to="/music" name="MUSIC" />
          <MenuItem to="/booking" name="BOOKING" />
        </ul>
      </div>
    </header>
  )
}

export default Header
