import React from 'react'
import Head from './head'
import Header from './header'
import Footer from './footer'

import '../sass/main.scss'

const Layout = ({ children }) => {
	return (
		<div className="sitewrapper">
			<Head />
			<Header />
			{children}
			<Footer />
		</div>
	)
}

export default Layout
