import React from 'react'
import { Subscribe } from '../../utils'
import MailIcon from 'react-icons/lib/fa/envelope-o'

const SubscribeButton = () => {
	return (
		<button className="subscribebutton" onClick={Subscribe}>
			<MailIcon />
			Subscribe to our newsletter
		</button>
	)
}

export default SubscribeButton
