import React from 'react'
import { Helmet } from 'react-helmet'

const Head = () => {
  return (
    <Helmet
      title="The Human Nature"
      meta={[
        { name: 'description', content: 'descripton' },
        { name: 'someother', content: 'Sample' },
      ]}
      link={[
        {
          rel: 'stylesheet',
          type: 'text/css',
          href: 'https://fonts.googleapis.com/css?family=Titillium+Web:200,200italic,300,300italic',
        },
      ]}
      script={[
        {
          type: 'text/javascript',
          src: '//widget.songkick.com/widget.js',
        },
        {
          type: 'text/javascript',
          src: '/mailerlite.js',
        },
      ]}
    />
  )
}

export default Head
